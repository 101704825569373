import Head from 'next/head'
import { getCorrectPmPath } from '../../services/mapPmUrl'
import {
  getMeta,
  useOldIframe,
  parseMetaTag,
  KEEP_PATH,
  EXISTING_REDIRECTED_PATH,
} from '../../services/fetchFromOld'

// Server side
export async function getServerSideProps(context) {
  const { resolvedUrl, locale, defaultLocale, params } = context
  const contextPath = params.path?.[0]

  // Check if new vault path is detected
  const newVaultPath = getCorrectPmPath(resolvedUrl, '/passwords')
  if (newVaultPath) {
    return {
      redirect: {
        destination: newVaultPath,
        permanent: true,
      },
    }
  }

  // Redirection existed from old
  if (EXISTING_REDIRECTED_PATH[contextPath]) {
    const localePath = locale !== defaultLocale ? '/' + locale : ''
    return {
      redirect: {
        destination: EXISTING_REDIRECTED_PATH[contextPath]
          .replaceAll('{locale}', locale)
          .replaceAll('{localePath}', localePath),
        permanent: true,
      },
    }
  }

  // Path without prefix
  if (KEEP_PATH.includes(contextPath)) {
    return {
      redirect: {
        destination: `${locale !== defaultLocale ? '/' + locale : ''}/${resolvedUrl.replace('/passwords', '')}`,
        permanent: true,
      },
    }
  }

  const path = resolvedUrl.split('/passwords').slice(-1)[0]
  const { title, metaTags, url, error } = await getMeta({
    path,
    locale,
  })

  if (error) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      url,
      metaTags,
      title,
      locale,
      defaultLocale,
    },
  }
}

// Client page
export default function OldPmHome(props) {
  const { url, metaTags, title, locale, defaultLocale } = props
  const { currentTitle, currentMetaTags } = useOldIframe({
    locale,
    defaultLocale,
    title,
    metaTags,
  })

  // ---------- RENDER ----------

  return (
    <>
      <Head>
        <title>{currentTitle}</title>
        {currentMetaTags.map((i, index) => (
          <meta key={index} {...parseMetaTag(i)} />
        ))}
        <style
          dangerouslySetInnerHTML={{
            __html: `.woot--bubble-holder { display: none; } iframe .woot--bubble-holder { display: block; } #__next { height: 100%; overflow: hidden; }`,
          }}
        ></style>
      </Head>
      <iframe src={url} style={{ height: '100vh', width: '100%', borderWidth: 0 }}></iframe>
    </>
  )
}
