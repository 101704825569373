import axios from 'axios'
import * as cheerio from 'cheerio'
import { deleteCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import ENDPOINT from '../config/endpoint'

const BASE_URL = ENDPOINT.OLD_URL
// const BASE_URL = 'http://0.0.0.0:3011'

export const KEEP_PATH = [
  'blog',
  'contact',
  'about-us',
  'terms',
  'privacy',
  'master-password',
  'private-email',
]

export const PERNAMMENT_REDIRECT_PATH = [
  'business',
  'education',
  'features',
  'lifetime',
  'promo',
  'affiliate',
  'benefits',
  'download',
  'pricing',
  'refer-friend',
]

export const EXISTING_REDIRECTED_PATH = {
  'how-it-works': '{localePath}/passwords/benefits',
  plan: '{localePath}/passwords/pricing',
  plans: '{localePath}/passwords/pricing',
  'release-notes': 'https://support.locker.io/{locale}/locker-general/release-notes',
}

/**
 * Get title and meta tags from url
 * @param params
 * @returns
 */
export const getMeta = async (params: { path: string; locale: string }) => {
  const { path, locale } = params
  const fullPath = `${BASE_URL}${locale !== 'en' ? '/' + locale : ''}${path}`
  const urlObj = new URL(fullPath)
  const queryParams = new URLSearchParams(urlObj.search)
  queryParams.append('mode', 'iframe')
  urlObj.search = queryParams.toString()
  const url = urlObj.toString()

  try {
    const pageData = await axios.get(url.replace('-staging', ''))
    const html = pageData.data
    const { title, metaTags } = extractMetaFromHtml(html)
    return {
      url,
      title,
      metaTags,
    }
  } catch (e) {
    return {
      url,
      title: '',
      metaTags: [],
      error: e.toString(),
    }
  }
}

export const parseMetaTag = (tagString: string): { [key: string]: string } => {
  const regex = /<meta\s+([^>]+)\/?>/i
  const matches = tagString.match(regex)
  if (matches) {
    const attributes = {}
    const attributeRegex = /([^=\s]+)\s*=\s*("[^"]*"|'[^']*')/g
    const attributeMatches = matches[1].matchAll(attributeRegex)
    // @ts-ignore
    for (const match of attributeMatches) {
      const key = match[1]
      const value = match[2].replace(/^['"]|['"]$/g, '') // Remove surrounding quotes
      attributes[key] = value
    }
    return attributes
  }
  return {}
}

export const useOldIframe = (props: {
  locale: string
  defaultLocale: string
  title: string
  metaTags: string[]
}) => {
  const { locale, defaultLocale, title, metaTags } = props
  const router = useRouter()

  // ----------------- PARAMS -----------------

  const [currentTitle, setCurrentTitle] = useState(title)
  const [currentMetaTags, setCurrentMetaTags] = useState(metaTags)
  const currentLocale = useRef(locale)

  // Block continous url change
  const isLocked = useRef(false)

  // ----------------- METHODS -----------------

  const shouldAddPrefix = (path: string) => {
    return !KEEP_PATH.some((i) => path.startsWith(`/${i}`))
  }

  const onMessage = (e: MessageEvent) => {
    const value = e.data?.value

    switch (e.data?.event) {
      case 'url': {
        if (!value) {
          break
        }
        let iframeLocale = locale

        // Remove mode from url
        const urlObj = new URL(value)
        urlObj.searchParams.delete('mode')

        // Get corresponding path
        let path = urlObj.toString().split(BASE_URL).slice(-1)[0]
        if (path.startsWith('/vi')) {
          iframeLocale = 'vi'
          path = path.slice(iframeLocale.length + 1)
        } else if (path.startsWith('/zh')) {
          iframeLocale = 'zh'
          path = path.slice(iframeLocale.length + 1)
        }

        // Dont update history if path is /login or /register
        // Because they will be redirected soon
        if (path.startsWith('/register') || path.startsWith('/login')) {
          isLocked.current = true
          break
        }

        if (shouldAddPrefix(path)) {
          path = '/passwords' + path
        }
        if (iframeLocale !== defaultLocale) {
          path = `/${iframeLocale}${path}`
        }

        // Set new URL
        if (!window.location.href.endsWith(path) && !isLocked.current) {
          window.history.replaceState({}, '', path)

          // Fetch meta tags
          if (e.data?.html) {
            const { title, metaTags } = extractMetaFromHtml(e.data.html)
            setCurrentTitle(title)
            setCurrentMetaTags(metaTags)
          }
        }
        break
      }
      case 'home': {
        isLocked.current = true
        router.push('/', undefined, { locale: currentLocale.current })
        break
      }
      case 'external': {
        const urlObj = new URL(value)
        urlObj.searchParams.delete('ENVIRONMENT')
        urlObj.searchParams.append(
          'ENVIRONMENT',
          ENDPOINT.ENVIRONMENT === 'staging' ? 'iframe_staging' : 'iframe'
        )
        window.open(urlObj.toString(), '_self')
        break
      }
      case 'changeLang': {
        let currentPath = window.location.pathname
        if (currentLocale.current !== 'en') {
          currentPath = currentPath.split(`/${currentLocale.current}`)[1]
        }
        const path = `${value === 'en' ? '' : '/' + value}${currentPath}`
        currentLocale.current = value
        window.history.replaceState({}, '', path)
        break
      }
      case 'logout': {
        deleteCookie('cs_locker_token', { domain: '.locker.io', path: '/' })
        break
      }
    }
  }

  // ----------------- EFFECTS -----------------

  useEffect(() => {
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])

  // ----------------- RETURN -----------------

  return {
    currentTitle,
    currentMetaTags,
  }
}

const extractMetaFromHtml = (html: string) => {
  const $ = cheerio.load(html)
  const title = $('title').text()

  // Extract header tags
  const metaTags: string[] = []
  $('head meta').each((_, element) => {
    metaTags.push($.html(element))
  })

  return {
    title,
    metaTags,
  }
}
